import React from "react";
import { useMachine } from "@xstate/react";
import { Card, Button, Modal } from "@material-ui/core";
import { exportMachine, loaderMachine } from "./StateMachine/ClientMachines";
import { UploaderInput } from "./../../components/UploaderInputs/UploaderInput";
import "./scss/clients.scss";
import { FontIcon } from "../../components/Icons/FontIcon";
/* eslint eqeqeq: 0*/
export function ExportClientsToExcel() {
  const [current, send] = useMachine(exportMachine);
  const { value, context } = current;
  return (
    <div className="exportClientsButton">
      <label>
        <FontIcon
          iconName="fa-download"
          onClick={() => {
            send("FETCH");
          }}
        >
          <span>exportar a excel</span>
        </FontIcon>
      </label>

      <UploadStatusModal message={value} open={context.open} />
    </div>
  );
}
export function ImportClientsFromExcel({ onClose }) {
  const [current, send] = useMachine(loaderMachine);
  const { open, message } = current.context;
  console.log(current.value);
  return (
    <div className={"importClientButton"}>
      <UploaderInput
        accept="application/x-iwork-keynote-sffnumbers,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        name="content"
        title={<span>Importar clientes</span>}
        onChange={ev => {
          send({ type: "FETCH", ev, fn: onClose });
          return "";
        }}
      />
      <Modal open={open}>
        <div className="cardContainer">
          <Card>
            <div>
              <h1>{message}</h1>
            </div>
          </Card>
        </div>
      </Modal>
    </div>
  );
}
function UploadStatusModal({ message, open }) {
  const printMessage = value => {
    console.log(value);
    let message = "Cargando...";
    switch (value) {
      case "formatting":
        message = "Formateando datos...";
        break;
      case "generating":
        message = "Generando archivo para descarga...";
        break;
      case "success":
        message = "¡Completado!";
        break;
      case "failure":
        message = "Whoops, no pude obtener información";
        break;
      case "loading":
        message = "Obteniendo clientes...";
        break;
      default:
        break;
    }
    return message;
  };
  return (
    <Modal open={open}>
      <div className="cardContainer">
        <Card>
          <div>
            <h1>{printMessage(message)}</h1>
            {message == "failure" && <Button color="danger">Reintentar</Button>}
          </div>
        </Card>
      </div>
    </Modal>
  );
}
