import React, { useState, useEffect, useContext } from "react";
import { QrScan } from "./../../components/QR/QrScan";
import { FontIcon } from "./../../components/Icons/FontIcon";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { ActionInput } from "./../../components/CustomInput/ActionInput";
import { AuthFetch } from "./../../utils/AuthFetch";
import {
  optionalFn,
  playAudio,
  Helpers,
  isObjectEmpty
} from "./../../core/helpers";
import { ConditionalModal } from "../../components/CustomModal/ConditionalModal";
import { Image } from "../../components/Images/Image";
import Card from "components/Card/Card.jsx";
import {
  primaryColor,
  fontColor
} from "assets/jss/material-dashboard-react.jsx";
import validAccess from "assets/sound/validAccess.mp3";
import swal from "sweetalert";
import { SelectEvents } from "./../Events/EventSelectors";
import { InvitedClients } from "./AccessHistory";
/* eslint eqeqeq: 0*/
const EventContext = React.createContext(0);

export function AccessContainer() {
  const [event, setEvent] = useState(0);
  return (
    <>
      {event ? (
        <EventContext.Provider value={event}>
          <h2
            style={{ color: fontColor, textTransform: "full-width capitalize" }}
          >
            {event.name}
          </h2>
          <SearchClients />
        </EventContext.Provider>
      ) : (
        <SelectEvents
          times="current"
          onChange={({ label, value }) => {
            setEvent({ name: label, id: value });
          }}
        />
      )}
      <hr />
      {event ? <InvitedClients eventID={event.id} /> : ""}
    </>
  );
}
export function SearchClients() {
  const [searchValue, setSearch] = useState("");
  const [clients, setClients] = useState([]);
  const [showQr, toggleScan] = useState(1);
  const evContext = useContext(EventContext);
  const searchClients = async search => {
    const fetch = new AuthFetch("api/clients");
    fetch.get({ search }).then(items => {
      let data = items.data;
      console.log(data, data.length);
      if (data.length === 0) {
      }
      setClients(data);
    });
  };
  const findByHash = hash => {
    const fetch = new AuthFetch("api/access/hash");
    fetch.post({ hash, eventID: evContext.id }).then(ev => {
      console.log(ev);
      let data = ev.data;
      let message = ev.message;

      setSearch("");
      toggleScan(1);
      if (data["code"]) {
        return swal({
          title: message,
          timer: 3000,
          icon: "warning"
        });
      }
      playAudio(validAccess);
      swal({
        title: "Acceso Concedido",
        timer: 3000,
        icon: "success"
      });
    });
  };
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={2}>
          {showQr ? (
            <QrScan
              onScan={hash => {
                toggleScan(0);
                setSearch("");
                setClients([]);
                findByHash(JSON.parse(hash).h);
              }}
            />
          ) : (
            ""
          )}
        </GridItem>
        <GridItem xs={12} sm={10}>
          <ActionInput
            title="Buscar..."
            key={searchValue}
            icon={<FontIcon iconName="fa-search" />}
            autoFocus
            onBlur={({ target }) => {
              setTimeout(function() {
                if (target) target.focus();
              }, 1);
            }}
            value={searchValue}
            onClick={ev => {
              setSearch(ev);
              const qrCode = new Helpers().isJsonString(ev);
              if (qrCode) {
                return findByHash(qrCode.h);
              }
              searchClients(ev);
            }}
          />
          <ClientsGrid
            clients={clients}
            onSelection={client => {
              setSearch("");
              setClients([]);
            }}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
function ClientsGrid({ clients = [], onSelection }) {
  const [selectedClient, setSelecction] = useState(0);
  const [open, toggleCard] = useState(0);
  console.log(open);
  return (
    <>
      <GridContainer>
        {clients.map((client, key) => (
          <GridItem xs={6} md={3} key={key}>
            <ClientMiniCard
              photo={client.photo}
              name={client.fullname}
              onClick={() => {
                setSelecction(key);
                toggleCard(1);
              }}
            />
          </GridItem>
        ))}
      </GridContainer>
      <ClientAccessCard
        client={clients[selectedClient] || {}}
        open={open}
        onAccess={() => {
          toggleCard(0);
          playAudio(validAccess);
          optionalFn(onSelection)();
          swal({
            title: "Acceso Concedido",
            timer: 3000,
            icon: "success"
          });
        }}
        onClose={() => {
          toggleCard(0);
        }}
      />
    </>
  );
}
function ClientMiniCard({ photo, name, onClick }) {
  const [hover, setHover] = useState(0);
  return (
    <Card
      style={{ background: !hover ? "white" : primaryColor, cursor: "pointer" }}
      onMouseEnter={() => {
        setHover(1);
      }}
      onMouseLeave={() => {
        setHover(0);
      }}
    >
      <GridContainer
        onClick={() => {
          optionalFn(onClick)();
        }}
      >
        <GridItem xs={4}>
          <Image src={photo} style={{ height: "60px" }} />
        </GridItem>
        <GridItem xs={8}>
          <center>
            <strong>{name}</strong>
          </center>
        </GridItem>
      </GridContainer>
    </Card>
  );
}
function ClientAccessCard({ client, open, onClose, onAccess }) {
  const evContext = useContext(EventContext);
  const [openCard, toggleCard] = useState(0);
  const { photo, fullname, id } = client;
  useEffect(() => {
    hasBeenInvited();
    // eslint-disable-next-line
  }, [open]);
  const grantAccess = id => {
    const fetch = new AuthFetch("api/access");
    fetch.post({ clientID: id, eventID: evContext.id }).then(({ data }) => {
      optionalFn(onAccess)(data);
    });
  };
  const hasBeenInvited = () => {
    if (isObjectEmpty(client)) {
      return false;
    }
    const fetch = new AuthFetch("api/invitations");
    fetch.get({ clientID: id, eventID: evContext.id }).then(({ data }) => {
      if (data.length == 0) {
        swal({
          title: `${fullname} carece de invitación. Favor de pasar a caja`,
          timer: 3000,
          icon: "warning"
        });
        optionalFn(onClose)();
      } else {
        toggleCard(open == 1);
      }
    });
  };
  return (
    <>
      <ConditionalModal
        title={"¿Permitir?"}
        open={openCard}
        onSubmit={answer => {
          toggleCard(0);
          if (!answer) {
            return optionalFn(onClose)();
          }
          //hasBeenInvited();
          return grantAccess(id);
        }}
        onClose={() => {
          toggleCard(0);
          optionalFn(onClose)();
        }}
      >
        <Image
          src={photo}
          style={{
            height: "300px",
            width: "300px",
            display: "block",
            margin: "auto"
          }}
        />
        <center>
          <strong>{fullname}</strong>
        </center>
      </ConditionalModal>
    </>
  );
}
