import { json2excel, excel2json } from "js2excel";
import { Machine, assign } from "xstate";
import { Helpers, optionalFn } from "./../../../core/helpers";
import { AuthFetch } from "./../../../utils/AuthFetch";
import { Translator, findOriginal } from "./../../../utils/Translator";
/* eslint eqeqeq: 0*/
export const exportMachine = Machine({
  id: "fetch",
  initial: "idle",
  context: { data: [], message: "Cargando...", open: false },
  states: {
    idle: {
      on: {
        FETCH: { target: "loading", actions: assign({ open: () => true }) }
      }
    },
    loading: {
      invoke: {
        id: "fecthData",
        src: (contex, data) => {
          return (callback, event) => {
            let fetch = new AuthFetch("api/clients");
            fetch.get().then(({ data }) => {
              if (data.length == 0) {
                callback({ type: "REJECT", data });
              } else callback({ type: "RESOLVE", data });
            });
          };
        }
      },
      on: {
        RESOLVE: "formatting",
        REJECT: "failure"
      }
    },
    formatting: {
      on: {
        export: "generating"
      },
      invoke: {
        id: "formatting",
        src: (context, { data }) => {
          let helpers = new Helpers();
          data = data.map(item => {
            let keys = Object.keys(item);
            let content = {};
            for (let key of keys) {
              let value = item[key];
              if (key == "phones") {
                let phone = helpers.isJsonString(value);
                value = Array.isArray(phone) ? phone.join() : value;
              }
              content[new Translator(key).get()] = value;
            }
            return content;
          });
          return (callback, EventForm) => {
            callback({ type: "export", data });
          };
        }
      }
    },
    generating: {
      on: {
        success: { target: "success" },
        failure: "failure"
      },
      invoke: {
        id: "generating",
        src: (context, { data }) => {
          return (callback, event) => {
            try {
              json2excel({
                data,
                name: "client-info-data" + new Date().toDateString(),
                formateDate: "yyyy/mm/dd"
              });
              callback({ type: "success" });
            } catch (e) {
              console.error("export error");
              callback({ type: "failure" });
            }
          };
        }
      }
    },
    canceled: {
      type: "final"
    },
    success: {
      invoke: {
        id: "scss",
        src: (context, { fn }) => {
          return callback => {
            setTimeout(() => {
              callback("closing");
            }, 2000);
          };
        }
      },
      on: {
        closing: {
          target: "idle",
          actions: assign({ open: 0 })
        }
      }
    },
    failure: {
      on: {
        RETRY: {
          target: "loading"
        }
      }
    }
  }
});
export const loaderMachine = Machine({
  id: "loader",
  initial: "idle",
  states: {
    context: {
      data: [],
      file: {},
      open: false,
      message: "",
      fn: null
    },
    idle: {
      on: {
        FETCH: {
          target: "reading",
          actions: assign({
            open: 1,
            message: "Subiendo Archivo",
            file: (context, { ev }) => {
              return ev.target.files;
            },
            fn: (context, { fn }) => fn
          })
        }
      }
    },
    reading: {
      invoke: {
        id: "readData",
        src: (context, { input }) => {
          return callback => {
            try {
              excel2json(
                context.file,
                data => {
                  callback({ type: "success", data });
                },
                "excel2json"
              );
            } catch (e) {
              console.log("fails", e);
            }
          };
        }
      },
      on: {
        success: {
          target: "fetchingData",
          actions: assign({
            data: (context, { data }) => {
              return Object.values(data)[0];
            },
            message: "Leyendo documento"
          })
        },
        fail: "failure"
      }
    },
    fetchingData: {
      invoke: {
        id: "fetchingColumns",
        src: (context, content) => {
          return callback => {
            const fetch = new AuthFetch("api/clients/meta");
            fetch.get().then(({ data }) => {
              if (data.length == 0) {
                callback({ type: "fail", data });
              } else callback({ type: "success", columns: data });
            });
          };
        }
      },
      on: {
        success: {
          target: "validating",
          actions: assign({ message: "validando datos" })
        },
        fail: "failure"
      }
    },
    validating: {
      invoke: {
        id: "validate",
        src: ({ data }, { columns }) => {
          return callback => {
            let format = data.map(item => {
              let content = {};
              for (let key in item) {
                let index = findOriginal(key);
                if (columns.indexOf(index) < 0) {
                  return callback({
                    type: "fail",
                    msg: `Mmmh, No reconozco la columna ${key}; Recuerda que las columnas deben ser las mismas que en la tabla`
                  });
                }
                content[index] = item[key];
              }
              return content;
            });

            callback({ type: "success", format });
          };
        }
      },
      on: {
        success: {
          target: "uploading",
          actions: assign({ message: "Cargando información" })
        },
        fail: "failure"
      }
    },
    uploading: {
      invoke: {
        id: "uploading",
        src: (content, { format }) => {
          return callback => {
            let fetch = new AuthFetch("api/clients/bulk");
            fetch.post({ data: format }).then(({ data }) => {
              if (data.length == 0) {
                callback("fail");
              }
              callback("success");
            });
          };
        }
      },
      on: {
        success: {
          target: "success",
          actions: assign({ message: "Completado" })
        },
        fail: "failure"
      }
    },
    success: {
      invoke: {
        id: "scss",
        src: ({ fn }, content) => {
          return callback => {
            setTimeout(() => {
              optionalFn(fn)();
              callback("closing");
            }, 2000);
          };
        }
      },
      on: { closing: { target: "idle", actions: assign({ open: false }) } }
    },
    failure: {
      invoke: {
        id: "fail",
        src: (content, data) => {
          console.log(data);
          return 1;
        },
        onDone: {
          actions: assign({
            message: (context, content) => {
              console.log(content);
            }
          })
        }
      },
      on: {
        RETRY: {
          target: "reading"
        }
      }
    }
  }
});
