import React, { useState } from "react";
import { AsyncDataTable } from "./../../components/Table/AsycnDataTable";
import { Image } from "./../../components/Images/Image";
import { AnchorList } from "./../../components/Anchors/AnchorList";
import { FaButton } from "../../components/CustomButtons/FaButton";
import {
  primaryColor,
  secondaryColor,
  dangerColor
} from "assets/jss/material-dashboard-react.jsx";
import { ClientForm } from "./ClientsForm";
import { CustomModal } from "./../../components/CustomModal/CustomModal";
import QR from "./../../components/QR/Qr";
import { AuthFetch } from "./../../utils/AuthFetch";
import { InvitationModal } from "../Events/InvitationContainer";
import swal from "sweetalert";
import { ExportClientsToExcel, ImportClientsFromExcel } from "./ClientActions";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
export function ClientContainer() {
  const [id, setID] = useState(null);
  const [open, toggleModal] = useState(0);
  const [key, setKey] = useState(0);

  const onDelete = async id => {
    const fetch = new AuthFetch(`api/clients/${id}`);
    let response = await swal({
      title: "Advertencia",
      text: `Estas a punto de eliminar informacion de contacto de este cliente, esto no puede deshacerse, ¿Deseas continuar?.`,
      showCancelButton: false,
      type: "input",
      dangerMode: true,
      buttons: { cancel: "No", confirm: "Eliminar" },
      icon: "warning"
    });

    if (response) {
      await fetch.delete();
      setKey(id);
    }
    return "";
  };

  return (
    <AsyncDataTable
      key={key}
      path="api/clients/paginated"
      title="Lista de clientes"
      subtitle="Lista para crear,editar y eliminar clientes"
      titles={{
        photo: "Foto",
        fullname: "Nombre Completo",
        email: "Correo",
        phones: "Teléfonos",
        actions: "Acciones"
      }}
      onOpen={() => {
        toggleModal(true);
      }}
      inModal={
        <ClientForm
          personID={id}
          onSave={() => {
            toggleModal(false);
            setID(null);
          }}
        />
      }
      prev={
        <GridContainer>
          <GridItem xs={6}>
            <ExportClientsToExcel />
          </GridItem>
          <GridItem xs={6}>
            <ImportClientsFromExcel
              onClose={() => {
                console.log("here");
                setKey(`id${Math.random()}`);
              }}
            />
          </GridItem>
        </GridContainer>
      }
      modalOpen={open}
      onClose={() => {
        toggleModal(false);
        setID(null);
      }}
      formatContent={item => {
        console.log(item.photo);
        return [
          <Image
            src={item.photo}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "100%",
              boxShadow: "1px 1px 3px #007777"
            }}
          />,
          item.fullname,
          item.email,
          <AnchorList
            key={`phones-${item.phones}`}
            type="tel"
            items={item.phones}
          />,
          <>
            <FaButton
              icon="edit"
              style={{ color: primaryColor, fontSize: "12px" }}
              onClick={() => {
                setID(item.id);
                toggleModal(true);
              }}
            />
            <ClientQR id={item.id} />
            <InvitationModal id={item.id} />
            <FaButton
              icon="trash"
              style={{ color: dangerColor, fontSize: "12px" }}
              onClick={() => {
                onDelete(item.id);
              }}
            />
          </>
        ];
      }}
    />
  );
}
export function ClientQR({ id }) {
  const [hash, setHash] = useState("{h:''}");
  const [open, toggle] = useState(0);

  const fetch = new AuthFetch(`api/clients/${id}`);
  const loadClient = async () => {
    const data = (await fetch.get()).data;
    setHash(`{"h":"${data.hash}"}`);
  };

  return (
    <>
      <FaButton
        icon="qrcode"
        style={{ color: secondaryColor, fontSize: "12px" }}
        onClick={() => {
          loadClient().then(() => {
            toggle(1);
          });
        }}
      />
      <CustomModal
        open={open}
        onClose={() => {
          toggle(0);
        }}
      >
        <QR
          data={hash}
          onReload={() => {
            fetch.put({ hash: true }).then(({ data }) => {
              setHash(`{h:"${data.hash}"}`);
            });
          }}
        />
      </CustomModal>
    </>
  );
}
