import React, { useEffect, useState } from "react";
import { AuthFetch } from "./../../utils/AuthFetch";
import { DataTable } from "./../../components/Table/DataTable";
import { FontIcon } from "../../components/Icons/FontIcon";

import { AsyncDataTable } from "./../../components/Table/AsycnDataTable";
import {
  successColor,
  grayColor
} from "assets/jss/material-dashboard-react.jsx";
export function AccessHistory() {
  return (
    <AsyncDataTable
      path={"api/access/paginated"}
      title="Lista de accesos"
      titles={{
        fullname: "Nombre del cliente",
        name: "evento",
        created_at: "Fecha"
      }}
      subtitle=" "
      hideButton
      formatContent={item => [
        item.fullName,
        item.name || "Evento descartado",
        item.created_at
      ]}
    />
  );
}
export function InvitedClients({ eventID }) {
  const [attendance, setAttendance] = useState([]);
  const loadAttendanceList = (eventID = "") => {
    const fetch = new AuthFetch(`api/invitation/attendance/${eventID}`);
    fetch.get().then(({ data }) => {
      setAttendance(
        data.map(item => {
          return [
            item.fullname,
            <FontIcon
              iconName={item.accessID ? "fa-check-square" : "fa-square"}
              style={{ color: item.accessID ? successColor : grayColor }}
            />
          ];
        })
      );
    });
  };
  useEffect(() => {
    loadAttendanceList(eventID);
    let interval = setInterval(() => {
      loadAttendanceList(eventID);
    }, 7000);
    return () => {
      clearInterval(interval);
    };
  }, [eventID]);
  return (
    <>
      <DataTable
        title="Asistencia"
        hideButton
        subtitle=" "
        titles={["nombre", "Asistencia"]}
        data={attendance}
      />
    </>
  );
}
