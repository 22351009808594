import React, { Component } from "react";
import "./scss/all.min.scss";
import { infoColor } from "../../assets/jss/material-dashboard-react";
export class FontIcon extends Component {
  render() {
    const { iconName, ...rest } = this.props;
    return (
      <i className={`fa ${iconName}`} style={{ color: infoColor }} {...rest} />
    );
  }
}
