import React from "react";
import {
  ExportClientsToExcel,
  ImportClientsFromExcel
} from "../Clients/ClientActions";
export function Sandbox() {
  return (
    <>
      <ExportClientsToExcel />
      <ImportClientsFromExcel />
    </>
  );
}
