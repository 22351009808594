import React from "react";
import { AsyncSelector } from "../../components/CustomInput/AsyncSelector";
export function SelectEvents({ times = "", ...rest }) {
  return (
    <>
      <AsyncSelector
        {...rest}
        path={`api/events/${times}`}
        title={"Selector de eventos"}
        formatOptions={item => {
          let value = item.id;
          let title = item.name;
          return { value, title };
        }}
      />
    </>
  );
}
