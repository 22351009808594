import React, { useState, useEffect } from "react";
import { FaButton } from "./../../components/CustomButtons/FaButton";
import { CustomModal } from "./../../components/CustomModal/CustomModal";
import { SelectEvents } from "./EventSelectors";
import GridItem from "components/Grid/GridItem.jsx";
import { PanelContainer } from "../../components/Panel/PanelContainer";
import { AuthFetch } from "./../../utils/AuthFetch";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card.jsx";
import {
  infoColor,
  dangerColor
} from "assets/jss/material-dashboard-react.jsx";
import { optionalFn } from "../../core/helpers";
import swal from "sweetalert";
export function InvitationModal({ id }) {
  const [key, setKey] = useState("00");
  const [open, toggleOpen] = useState(0);
  const setInvitation = (eventID, clientID) => {
    const fetch = new AuthFetch("api/invitations");
    fetch.post({ eventID, clientID }).then(({ data }) => {
      setKey(`${eventID},${id}`);
      swal({
        title: "Cliente ha sido invitado",
        timer: 2000,
        icon: "success"
      });
    });
  };
  return (
    <>
      <FaButton
        icon={"calendar"}
        style={{ fontSize: "12px", color: infoColor }}
        onClick={() => {
          toggleOpen(1);
        }}
      />
      <CustomModal
        open={open}
        styles={{ width: "900px" }}
        onClose={() => {
          toggleOpen(0);
        }}
      >
        <PanelContainer title={"Invitaciones"}>
          <GridItem xs={12}>
            <SelectEvents
              times="future"
              onChange={ev => {
                setInvitation(ev.value, id);
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <InvitationsGrid
              clientID={id}
              key={key}
              onDelete={id => {
                const fetch = new AuthFetch(`api/invitations/${id}`);
                fetch.delete().then(() => {
                  setKey(id);
                });
              }}
            />
          </GridItem>
        </PanelContainer>
      </CustomModal>
    </>
  );
}
export function InvitationsGrid({ clientID, ...rest }) {
  const [invitations, setInvitations] = useState([]);
  let loadInvitations = () => {
    const fetch = new AuthFetch("api/invitations");
    fetch.get({ clientID }).then(({ data }) => {
      setInvitations(data);
    });
  };
  useEffect(() => {
    loadInvitations();
    // eslint-disable-next-line
  }, [clientID]);
  return (
    <>
      <GridContainer>
        {invitations.map((invitation, key) => {
          const { name, startDate, endDate, id } = invitation;
          return (
            <>
              <GridItem xs={4} key={key}>
                <InvitationCard
                  id={id}
                  name={name}
                  startDate={startDate}
                  endDate={endDate}
                  {...rest}
                />
              </GridItem>
            </>
          );
        })}
      </GridContainer>
    </>
  );
}
export function InvitationCard({ id, name, startDate, endDate, onDelete }) {
  return (
    <Card>
      <GridContainer>
        <GridItem xs={12}>
          <center>
            <strong>{name}</strong>
          </center>
        </GridItem>
        <GridItem xs={6}>
          <p>Desde: {startDate}</p>
        </GridItem>
        <GridItem xs={6}>
          <p>Hasta: {endDate}</p>
        </GridItem>
        <GridItem xs={12}>
          <center>
            <FaButton
              icon="trash"
              style={{ color: dangerColor }}
              onClick={() => {
                optionalFn(onDelete)(id);
              }}
            />
          </center>
        </GridItem>
      </GridContainer>
    </Card>
  );
}
