// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views
import { ColorManager } from "./../views/Colors/ColorManager";
import { ProfileContainer } from "./../views/Profiles/ProfileContainer";
import { UsersContainer } from "./../views/Users/UsersContainer";
import { ClientContainer } from "./../views/Clients/ClientContainer";
import { AccessContainer } from "./../views/Access/AccessContainer";
import { EventContainer } from "./../views/Events/EventContainer";
import { AccessHistory } from "./../views/Access/AccessHistory";
import { Sandbox } from "./../views/Sandbox/Sandbox";
// mis views

export class DashboardRoutes {
  get = () => {
    return this.routes;
  };

  routes = [
    {
      redirect: true,
      path: "/",
      to: "/dashboard",
      navbarName: "Redirect"
    },
    {
      path: "/dashboard",
      sidebarName: "Inicio",
      navbarName: "Inicio",
      icon: Dashboard,
      component: ClientContainer
    },
    {
      path: "/access",
      sidebarName: "Accesos",
      navbarName: "Accesos",
      icon: "door-open",
      component: AccessContainer
    },
    {
      path: "/events",
      sidebarName: "Eventos",
      navbarName: "Eventos",
      icon: "calendar",
      component: EventContainer
    },
    {
      path: "/history",
      sidebarName: "historial",
      navbarName: "historial",
      icon: "stopwatch",
      component: AccessHistory
    },
    {
      path: "/users",
      sidebarName: "usuarios",
      navbarName: "usuarios",
      icon: "users",
      component: UsersContainer
    },
    {
      path: "/clients",
      sidebarName: "Clientes",
      navbarName: "Clientes",
      icon: "users",
      component: ClientContainer
    },
    {
      path: "/config/color",
      sidebarName: "Colores",
      navbarName: "Colores",
      icon: "brush",
      component: ColorManager
    },
    {
      path: "/config/permissions",
      hidden: true,
      sidebarName: "Perfiles",
      navbarName: "Perfiles",
      icon: "user-cog",
      component: ProfileContainer
    },
    {
      path: "/sandbox",
      hidden: true,
      sidebarName: "sbx",
      navbarName: "sbx",
      icon: "user-cog",
      component: Sandbox
    }
  ];
}
